<template>
  <div id="app">
    <NavBar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'
export default {  
  components: {
    NavBar, Footer
  },
};
</script>


<style lang="scss">
@font-face{
  font-family:"Grifa";
    src: local("Grifa"),
    url("./assets/Grifa-Bold.ttf") format("truetype");  
}
@font-face{
  font-family:"Museo Slab";    
    src: local("Museo Slab"), url("./assets/MuseoSlab-500.woff") format("woff");
}
#app {
  font-family: "Grifa", "Museo Slab";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;  
}

</style>
