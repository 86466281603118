<template>
  <div id="nav">      
    <div class="bg">
      <BackSVG id="svg"/>
    </div>                    
    <b-button id="logo" to="/"><b-img width="250px" max-height="250px" :src='require("@/assets/logo.png")'></b-img></b-button>          
    <b-container class="nav-row">
      <b-row align-h="end" class="my-4 py-3 px-auto">      
        <b-col lg="8" class="buttons">        
          <b-button id="story" to="/historia" variant="warning" class="text-white mx-4">La historia de Leonardo</b-button>
          <b-button id="about" to="/nosotros" class="text-white mx-4">Sobre Nosotros</b-button>
          <b-button id="cont" to="/contacto" class="text-white mx-3 ">Contacto</b-button>
          <b-button id="donationbutton"  to="/donaciones" class="text-white mx-4 px-3">Donaciones</b-button>                  
        </b-col>                         
      </b-row>          
      <b-dropdown block menu-class="w-100" text="Menú Principal" class="mx-auto mobile-nav" size="lg" variant="outline-light" >              
        <b-dropdown-item href="/historia">Historia</b-dropdown-item>
        <b-dropdown-item href="/nosotros">Sobre Nosotros</b-dropdown-item>
        <b-dropdown-item href="/contacto">Contacto</b-dropdown-item>
        <b-dropdown-item href="/donaciones">Donaciones</b-dropdown-item>              
      </b-dropdown>          
    </b-container>
  </div>
</template>
<script>
import BackSVG from '../assets/nav.svg';

export default {
  name:"NavBar",
    components: { BackSVG },
    // ...Other stuff here
}
</script>
<style lang="scss">
#nav {
  display:flex;
  background-color:#467EB7;
  font-family: "Grifa";
  font-weight: bold;
}
.nav-row{    
  z-index:5;
  left:8%;    
}

#story,#about,#cont {         
  background-color: transparent;
  border-color: transparent;
  border-radius: 15px;
  z-index: 5;  
}

.buttons{
  display: flex;  
  margin-right: -175px !important;
}

#donationbutton {    
  background-color:gold;
  border-color:gold;
  border-radius: 15px;  
}

#logo{
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    margin:50px;
    z-index:5;    
    top:15px;
}
@media screen and (min-width: 1200px){
  .bg{          
    z-index: 1;
    position:absolute;
    top:-30px;
    transform:scale(82.2%,85%);  
    left:-230px;      
  }
  .mobile-nav{  
    display: none;  
  }
}
@media screen and (max-width: 1199px){
  #logo{
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    margin:50px;
    z-index:5;    
    top:25px;
}
  .mobile-nav{           
    z-index:5;              
    position:absolute !important;  
    left:60%; 
    top:10%;
  }
  .buttons{
    display:none;
  }
}

@media screen and (max-width: 500px){
  #logo{
    background-color: transparent;
    border-color: transparent;
    position: absolute;
    margin:10%;
    z-index:5;    
    top:-15px;
  }
  .mobile-nav{           
    z-index:5;              
    position:absolute !important;  
    left:30%; 
    top:250px;
  }
}
</style>
