<template>
  <div id="footer" style="background-color: #467EB7;">
    <b-container style="background-color: #467EB7;" class="mt-5">
      <b-row class="mt-5 py-5">
        <b-col lg="2" class=" my-2">
          <b-button to="/" style="background-color: transparent; border-color: transparent;">
            <b-img :src='require("@/assets/logo-dim.png")' width="40" id="donationicon" fluid-grow></b-img>
          </b-button>
        </b-col>
        <b-col lg="3" offset-lg="1" class=" my-2">
          <p class="text-white">© Leonardo Un Milagro de Esperanza A.C</p>
          <p class="text-white">Todos los derechos reservados</p>
        </b-col>
        <b-col lg="2" offset-lg="1" class=" my-2">
          <b-row>
            <router-link to="/" class="text-white mx-auto mb-1" style="text-decoration: none; text-align: left;">Inicio</router-link>
          </b-row>
          <b-row>
            <router-link to="/privacidad" class="text-white mx-auto my-1" style="text-decoration: none; text-align: left;">Aviso de Privacidad</router-link>
          </b-row>
          <b-row>            
          <router-link to="/donaciones" class="text-white mx-auto my-1" style="text-decoration: none; text-align: left;">Dona ahora</router-link>
          </b-row>
          <b-row>
            <router-link to="/contacto" class="text-white mx-auto mt-1" style="text-decoration: none; text-align: left;">Contacto</router-link>
          </b-row>
        </b-col>        
        <b-col lg="3" class="my-2">
          <b-button href="https://www.facebook.com/leonardoacmx/" class="" style="background-color: transparent; border-color: transparent;">
            <b-icon icon="facebook" variant="white" scale="2" class="mx-4 mt-4"></b-icon>
          </b-button>
          <b-button href="https://www.instagram.com/leonardo_acmx/"  class="my-auto mx-auto" style="background-color: transparent; border-color: transparent;">
            <b-icon icon="instagram" variant="white" scale="2" class="mx-4 mt-4"></b-icon>
          </b-button>
          <p class="text-white mt-4">Powered by MIMICA</p>
        </b-col>        
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name:"Footer"
}
</script>

<style scoped>
#footer {
  font-family:"Museo Slab";
}
</style>