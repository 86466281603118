<template>
  <div class="home">
    <b-jumbotron class="jumbo">
      <b-img :src='require("@/assets/home.jpg")' right fluid></b-img>
    </b-jumbotron>
    <b-button id="donationtoast" class="text-white py-5 px-4" to="/donaciones">
      <b-row>
        <b-col class="my-auto"> <div id="donationtoasttext">
            Conoce, dona, o sé parte ahora
          </div>
        </b-col>
        <b-col class="my-auto">
          <b-img :src='require("@/assets/donate-icon.png")' width="70" id="donationicon"></b-img>
        </b-col>
      </b-row>
    </b-button>   
    <b-row class="homelower">      
      <b-col lg="6" offset-lg="3" variant="warning">
        <h1 class="mx-5 py-5">Hemos ayudado a más de 50 familias chihuahuenses</h1>      
      </b-col>
    </b-row>   
    <b-container class="homebottom pb-3">
      <b-row class="mx-auto my-5">      
        <b-col lg="6" offset-lg="1">        
          <b-card no-body  class="card mb-5 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/ribbon-small.png")' style="width:50px"></b-card-img>
              </b-col>
              <b-col cols="" class="my-auto text-left">
                <b-card-text><h4 style="color:#467EB7" class="mx-2">Nuestras metas</h4></b-card-text>
              </b-col>
            </b-row>
          </b-card>  
          <b-card no-body  class="card mb-5 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/cross-icon.png")' style="width:50px"></b-card-img>
              </b-col>
              <b-col cols="" class="my-auto text-left">
                <b-card-text  class="mx-2"><p>Apoyar con medicamentos e insumos para la aplicación de quimioterapias</p></b-card-text>
              </b-col>
            </b-row>
          </b-card>  
          <b-card no-body  class="card mb-5 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/cross-icon.png")' style="width:50px"></b-card-img>
              </b-col>
              <b-col cols="" class="my-auto text-left">
                <b-card-text  class="mx-2"><p>Ayudar a familias de los niños con apoyo:</p></b-card-text>
              </b-col>
            </b-row>
          </b-card>          
        </b-col>
        <b-col lg="5" offset="" class="pt-3 mt-5">
          <b-card no-body  class="card mb-5 pt-5 pb-1 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/coins.png")' style="width:50px"></b-card-img>
              </b-col>
              <b-col cols="" class="my-auto text-left">
                <b-card-text class="mx-2"><h3 style="font-weight:bold;">Económico</h3></b-card-text>
              </b-col>
            </b-row>
          </b-card>      
          <b-card no-body  class="card mb-5 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/heart.png")' style="width:50px" fluid></b-card-img>
              </b-col>
              <b-col cols="" offset="" class="my-auto text-left">
                <b-card-text class="mx-2"><h3 style="font-weight:bold;">Emocional</h3></b-card-text>
              </b-col>
            </b-row>
          </b-card>      
          <b-card no-body  class="card mb-5 overflow-hidden">
            <b-row no-gutters>
              <b-col cols="2" class="my-auto">
                <b-card-img :src='require("@/assets/pray.png")' style="width:50px"></b-card-img>
              </b-col>
              <b-col cols="" offset="" class="my-auto text-left">
                <b-card-text class="mx-2"><h3 style="font-weight:bold;">Espiritual</h3></b-card-text>
              </b-col>
            </b-row>            
          </b-card>      
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" class="mx-auto">
          <h1 class="mx-auto mt-5"  style="color:#467EB7">Involúcrate ahora y cambia la vida de más familias</h1>
        </b-col>
      </b-row>      
      <b-row class="my-5">
        <b-col lg="6">
          <b-button block to="/nosotros" class="home-buttons mb-5" size="lg">Acerca de nosotros</b-button>
        </b-col>
        <b-col lg="6">
          <b-button block to="/donaciones" class="home-buttons mb-5" size="lg">Dona ahora</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: "Home",
  components: {
    
  },
};
</script>

<style scoped>
.home{
  font-family:"Museo Slab";  
}

.jumbo{
  
  z-index:1;
}
.homelower {
  background-color: gold; 
  color:white;
}
.card{
  border-color: transparent;
  background-color: transparent;
}
.text-left{
  text-align: left;
}
.homebottom{
  background: url('~@/assets/background.png') 25% 0% no-repeat;
  background-size: 70%;
  
}
.home-buttons{
  background-color:gold; 
  border-radius: 15px; 
  border-color:gold;
  width:50%;  
  font-family: "Grifa";
}
#donationtoast {   
  font-family: "Grifa";
  position:absolute; 
  background-color: gold;
  border-color: gold;
  border-radius: 35px 0 0 35px;    
  z-index:5;
  top:55vw; 
  right:0;   
  font-size: 24px;
}
@media screen and (max-width:1199px){
  #donationtoast {   
    display:none;
  }
  .jumbo{
    margin-top: -2%;
    z-index:1;
}
}
#donationtoasttext {
  text-align: end;
}

#donationicon {
  margin-right:25%;
}
</style>